import React, { ReactNode } from 'react';
import {
  formatMetric,
  Metric,
  metricTitle
} from '../../../../../services/analyticsV2/metrics';
import {
  LoadingValue,
  useMappedLoadingValue
} from '../../../../../services/db';
import {
  AnalyticsResponseRowWithComparison,
  CountUniqOfAnalyticsField
} from '../../../../../domainTypes/analytics_v2';
import { useSpaceCurrency } from '../../../../../services/useSpaceCurrency';
import { isNil } from 'lodash';
import { Dash } from '../../../../../components/Table/CountCell';
import { FlexContainer } from '../../../../../layout/Flex';
import { css, styled } from '../../../../../emotion';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from 'react-feather';
import { SegmentTotalsResponse } from './useSegmentQuery';

const BaseCell: React.FC<{ title: ReactNode; tooltipContent?: ReactNode }> = ({
  title,
  children,
  tooltipContent
}) => {
  return (
    <FlexContainer direction="column" className={css(() => ({ flexGrow: 1 }))}>
      {tooltipContent ? (
        <Tooltip title={tooltipContent} placement="top">
          <Typography color="textSecondary" variant="caption">
            {title}{' '}
            <Info
              className={css(() => ({
                position: 'relative',
                top: 2,
                right: -2
              }))}
              size={14}
            />
          </Typography>
        </Tooltip>
      ) : (
        <Typography color="textSecondary" variant="caption">
          {title}
        </Typography>
      )}
      <Typography variant="body2">{children}</Typography>
    </FlexContainer>
  );
};

const NumberSkeleton = styled(Skeleton)`
  width: 2rem;
  font-size: 1.5rem;
`;

const MetricCell: React.FC<{
  metric: Metric;
  totals: LoadingValue<AnalyticsResponseRowWithComparison['data']>;
  tooltipContent?: ReactNode;
}> = ({ metric, totals, tooltipContent }) => {
  const currency = useSpaceCurrency();
  const [data, loading] = totals;
  return (
    <BaseCell title={metricTitle(metric)} tooltipContent={tooltipContent}>
      {!data || loading ? (
        <NumberSkeleton />
      ) : isNil(data[metric]?.curr) ? (
        <Dash size={12} />
      ) : (
        formatMetric(data[metric]?.curr ?? 0, metric, currency)
      )}
    </BaseCell>
  );
};

const UniqCountCell: React.FC<{
  field: CountUniqOfAnalyticsField;
  title: string;
  totals: LoadingValue<AnalyticsResponseRowWithComparison['data']>;
}> = ({ field, totals, title }) => {
  const [data, loading] = totals;
  return (
    <BaseCell title={title}>
      {!data || loading ? (
        <NumberSkeleton />
      ) : isNil(data[field]?.curr) ? (
        <Dash size={12} />
      ) : (
        data[field]?.curr
      )}
    </BaseCell>
  );
};

const SegmentTotalsWrapper = styled('div')`
  border: 1px solid ${(p) => p.theme.palette.divider};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  margin: ${(p) => p.theme.spacing(2)}px 0;
  padding: ${(p) => p.theme.spacing(2)}px;
`;

export const SegmentTotals: React.FC<{
  totalsResponse: SegmentTotalsResponse;
}> = ({ totalsResponse }) => {
  const totals = useMappedLoadingValue(
    totalsResponse,
    (value) =>
      value.totals.analytics as AnalyticsResponseRowWithComparison['data']
  );

  const [isEpcEstimated = false] = useMappedLoadingValue(
    totalsResponse,
    (r) => r.estimated
  );

  return (
    <SegmentTotalsWrapper>
      <FlexContainer
        className={css(() => ({
          minWidth: 500
        }))}
      >
        <UniqCountCell
          title="Links"
          field="count_uniq_link_id"
          totals={totals}
        />
        <UniqCountCell
          title="Pages"
          field="count_uniq_page_url"
          totals={totals}
        />
        <UniqCountCell
          title="Products"
          field="count_uniq_p_catalog_uid"
          totals={totals}
        />
        <UniqCountCell
          title="Advertisers"
          field="count_uniq_l_advertiser_id"
          totals={totals}
        />
        <MetricCell metric="c" totals={totals} />
        <MetricCell metric="gmv_sum_net" totals={totals} />
        <MetricCell metric="commission_sum_net" totals={totals} />
        <MetricCell
          metric="epc_net"
          totals={totals}
          tooltipContent={
            isEpcEstimated
              ? 'This EPC is an estimate that blends together link-level EPCs and your account-wide Amazon EPC.'
              : null
          }
        />
      </FlexContainer>
    </SegmentTotalsWrapper>
  );
};
